import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { signUp } from '../../store/actions/authActions'

class SignUp extends Component {
  state = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signUp(this.state);
  }
  render() {
    const { auth, authError } = this.props;
    if (auth.uid) return <Redirect to='/' /> 
    return (
         <section class="header-content products p-info-section  ">

<div className="container">
        <form  onSubmit={this.handleSubmit}>
          <h4 className="h4">إنشاء حساب جديد</h4>
          <div className="form-group">
            {/* <label htmlFor="email">البريد الالكتروني</label> */}
            <input className="form-control" type="email" id='email' placeholder="البريد الالكتروني" onChange={this.handleChange} />
          </div>
          <div className="form-group">
            {/* <label htmlFor="password">الرقم السري</label> */}
            <input className="form-control" type="password" id='password' placeholder="الرقم السري" onChange={this.handleChange} />
          </div>
          <div className="form-group">
            {/* <label htmlFor="firstName">الاسم الأول</label> */}
            <input className="form-control" type="text" id='firstName' placeholder="الاسم الأول" onChange={this.handleChange} />
          </div>
          <div className="form-group">
            {/* <label htmlFor="lastName">اللقب</label> */}
            <input className="form-control" type="text" id='lastName' placeholder="اللقب" onChange={this.handleChange} />
          </div>
          <div className="form-group ">
            <button className="btn pink lighten-1 z-depth-0 ">سجل</button>
            <div className=" red-text">
              {/* { authError ? <p>{authError}</p> : null } */}
              { authError ? <p className="alert">يرجى إدخال البيانات</p> : null }
            </div>
          </div>
        </form>
      </div>
      </section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  }
}

const mapDispatchToProps = (dispatch)=> {
  return {
    signUp: (creds) => dispatch(signUp(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
