import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage'
import 'firebase/database'

// Replace this with your own config details
const config = {
  apiKey: "AIzaSyCxzdgUep8Op3xfDWP7bgJJX2IglQVhrwc",
  authDomain: "martyrs-69f15.firebaseapp.com",
  databaseURL: "https://martyrs-69f15.firebaseio.com",
  projectId: "martyrs-69f15",
  storageBucket: "martyrs-69f15.appspot.com",
  messagingSenderId: "1088897281254",
  appId: "1:1088897281254:web:3fb15aefe9f70c63e55f27",
  measurementId: "G-4PBY3MQ2B0"
};
firebase.initializeApp(config);
// firebase.firestore().settings({ timestampsInSnapshots: true });
firebase.firestore();
firebase.storage();

export default firebase 