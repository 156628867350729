const initState = {};

const projectReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_PROJECT_SUCCESS":
      //console.log('create project success');
      return state;
    case "CREATE_PROJECT_ERROR":
      //console.log('create project error');
      return state;
    case "CREATE_PROJECT_ERROR_boards":
      //console.log('create project error boards');
      return state;
    case "CREATE_PROJECT_ERROR_Name":
      //console.log('create project error Name');
      return state;
    case "EDIT_PROJECT_SUCCESS":
      //console.log('EDIT project success');
      return state;
    case "EDIT_PROJECT_ERROR":
      //console.log('EDIT project error');
      return state;
    case "SEND_MARTYR__SUCCESS":
      //console.log('send martyr success');
      return state;
    case "SEND_MARTYR__ERROR":
      //console.log('EDIT send martyr  error');
      return state;
    default:
      return state;
  }
};

export default projectReducer;
