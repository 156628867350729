import React from "react";
// import ProjectSummary from './ProjectSummary'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { compose, spacing, palette, breakpoints } from "@material-ui/system";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ModalVideo from "react-modal-video";

import { useState } from "react";

const VideosList = ({ props, videos, admin }) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const getGridListCols = () => {
    if (lg) {
      return 3;
    }
    if (md) {
      return 2;
    }

    if (sm) {
      return 1;
    }

    return 1;
  };

  //set Grid Height
  const getGridListHeight = () => {
    if (lg) {
      return 600;
    }
    if (md) {
      return 600;
    }

    if (sm) {
      return 450;
    }

    return 300;
  };

  //custom UI for DataTable
  let getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiGridListTile: {
          tile: {
            boxShadow: " 0 4px 8px 0 rgba(0, 0, 0, 0.2)",
          },
        },
      },
    });

  let data = [];

  const [open, setOpen] = useState({ isOpen: false, url: "" });

  return (
    <div className="container">
      <div>
        <ModalVideo
          channel="youtube"
          isOpen={open.isOpen}
          videoId={open.url}
          onClose={() => setOpen({ isOpen: false, url: "" })}
        />
      </div>
      {videos &&
        videos.map((video) => {
          //    //console.log("martyr", martyr);
          let e = [];
          e.push(video["name"]);
          e.push(video["img"]);
          e.push(video["details"]);
          e.push(video["url"]);
          e.push(video["id"]);
          data.push(e);
          //console.log("data", data);
        })}

      <MuiThemeProvider theme={getMuiTheme()}>
        <GridList
          cellHeight={getGridListHeight()}
          cols={getGridListCols()}
          spacing={20}
        >
          {data.map((tile) => (
            <GridListTile>
              <img
                src={tile[1]}
                alt={tile[1]}
                onClick={() => setOpen({ isOpen: true, url: tile[3] })}
                style={{ cursor: "pointer" }}
              />

              {admin ? (
                <GridListTileBar
                  title={tile[0]}
                  subtitle={<span> {tile[2]}</span>}
                  actionIcon={
                    <IconButton
                      aria-label={`info about ${tile.title}`}
                      onClick={() => {
                        props.history.push({
                          pathname: "/EditVideo/" + tile[4],
                          state: {
                            id: tile[4],
                          },
                        });
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  }
                />
              ) : (
                <GridListTileBar
                  title={tile[0]}
                  subtitle={<span> {tile[2]}</span>}
                  onClick={() => setOpen(true)}
                />
              )}
            </GridListTile>
          ))}
        </GridList>
      </MuiThemeProvider>
    </div>
  );
};

export default withWidth()(VideosList);
{
  /* <GridListTile>

<img src={tile[1]} alt={tile[1]} onClick={() => props.history.push({
  pathname: '/VideoDetails/' + tile[4],
})} style={{ cursor: 'pointer' }} />
<GridListTileBar
  title={tile[0]}
  subtitle={<span> {tile[2]}</span>}
  onClick={() => props.history.push({
    pathname: '/VideoDetails/' + tile[4],
  })}

/>

</GridListTile> */
}
