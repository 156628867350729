export const createVideos = (video) => {
    return (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();
        const profile = getState().firebase.profile;
        const authorId = getState().firebase.auth.uid;
        if (profile.role == 'admin') {
            firestore.collection('Videos').add({
                ...video,
            }).then(() => {
                dispatch({ type: 'CREATE_PROJECT_SUCCESS' });
            }).catch(err => {
                dispatch({ type: 'CREATE_PROJECT_ERROR' }, err);
            });
        }




    }
};

export const editVideo = (video, id) => {

    return (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();
        const profile = getState().firebase.profile;
        const authorId = getState().firebase.auth.uid;

        if (profile.role == 'admin') {
            firestore.collection('Videos').doc(id).set({
                ...video,
            }).then(() => {
                dispatch({ type: 'EDIT_PROJECT_SUCCESS' });
            }).catch(err => {
                dispatch({ type: 'EDIT_PROJECT_ERROR' }, err);
            });
        }


    }
};