import React from "react";
import { Link } from "react-router-dom";
import SignedInLinks from "./SignedInLinks";
import SignedOutLinks from "./SignedOutLinks";
import { connect } from "react-redux";

const Navbar = (props) => {
  const { auth, profile } = props;
  //console.log("auth:",auth,profile);
  const links = auth.uid ? (
    <SignedInLinks profile={profile} />
  ) : (
    <SignedOutLinks />
  );

  return (
    <nav className="navbar-fixed">
      <div className="container-fluid">
        <div className="navigation navigation-top clearfix"></div>

        <div className="navigation navigation-main">
          <a href="/" className="logo">
            <img src="/img/logo.png" alt="" />
          </a>

          {/* <!-- Mobile toggle menu --> */}
          <a href="#" className="open-menu">
            <i className="icon icon-menu"></i>
          </a>
          <div className="floating-menu">
            {/* <!-- Mobile toggle menu trigger--> */}

            <div className="close-menu-wrapper">
              <span className="close-menu">
                <i className="icon icon-cross"></i>
              </span>
            </div>
            {links}
          </div>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => {
  //console.log(state);
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(Navbar);
