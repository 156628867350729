import React, { Component } from "react";
import ReportsList from "../Reports/ReportsList";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import {  Link } from "react-router-dom";

import Button from "react-bootstrap/Button";

class Reports extends Component {
  render() {
    const {
      Reports,

      profile,

    } = this.props;
    //console.log("booook", Reports)

    // if (!auth.uid) return <Redirect to='/signin' />

    return (
      <div className="">
        <section className="header-content dash-container-gray">
          <div className="container-fluid">
            <div className="dashboard container">
              <div className="dash-container-gray">
                {profile.role == "admin" ? (
                  <Link
                    className="btn pink lighten-1 right "
                    to={{
                      pathname: "/CreateReports",
                    }}
                  >
                    <Button variant="info">إضافة تقرير</Button>
                  </Link>
                ) : (
                  ""
                )}
              </div>

              <div className="">
                {/* col s12 m6 */}
                <div className="container">
                  <ReportsList
                    reports={Reports}
                    props={this.props}
                    admin={profile.role == "admin" ? true : false}
                  />
                </div>
                {/* <div className="col s12 m5 offset-m1">
            <Notifications notifications={notifications} />
          </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    boards: state.firestore.ordered.boards,
    Reports: state.firestore.ordered.Reports,
    auth: state.firebase.auth,
    notifications: state.firestore.ordered.notifications,
    profile: state.firebase.profile,
  };
};

export default compose(
  firestoreConnect(() => [{ collection: "Reports", orderBy: ["order"] }]),
  connect(mapStateToProps)
)(Reports);
