import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './store/reducers/rootReducer'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { reduxFirestore, getFirestore } from 'redux-firestore';
//import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import { ReactReduxFirebaseProvider, getFirebase,reactReduxFirebase } from 'react-redux-firebase'
import firebase from './config/fbConfig'
import { BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { createFirestoreInstance } from 'redux-firestore'




const store = createStore(rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
   // reactReduxFirebase(fbConfig),
     reduxFirestore(firebase) // redux bindings for firestore
  )
);




const rrfConfig = { 
  userProfile: 'users',
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  attachAuthIsReady: true,
  // enableClaims: true // Get custom claims along with the profile
}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}




const mapStateToProps = (state) => ({
  authIsLoaded: state.firebase.auth && state.firebase.auth.isLoaded,
});
const WaitTillAuth = connect(mapStateToProps)(({ authIsLoaded }) => {
  if (!authIsLoaded) return <div className="intro"></div>;
  return (
    // my components that should only loaded after auth is loaded
    <App />
  );
});



// store.firebaseAuthIsReady.then(() => {
//   ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
//   registerServiceWorker();
// });


const app = (
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <BrowserRouter>
        <WaitTillAuth />
      </BrowserRouter>
    </ReactReduxFirebaseProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));