import React, { Component } from "react";
import { connect } from "react-redux";

import { Redirect } from "react-router-dom";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { useFirebase } from "react-redux-firebase";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { editReport } from "../../store/actions/ReportActions";

import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";

class EditReport extends Component {
  componentDidMount() {
    //console.log('thisprops', this.props.reportX)
    this.setNewState(this.props.reportX);
  }

  state = {
    id: "",
    name: "",
    details: "",
    img: "",
    url: "",
    order: "",
    isUploading: false,
    progress: 0,
    uploadingFlag: 0,
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
    //console.log(e);
    //console.log(state);
  };

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = (progress) => this.setState({ progress });
  handleUploadError = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = (filename) => {
    this.setState({ progress: 100, isUploading: false });
    this.props.firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then((url) => this.setState({ img: url }));

    toast.success("Success Notification !", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });

    //console.log('imgfun',this.state)
  };

  handleUploadSuccessReport = (filename) => {
    this.setState({ progress: 100, isUploading: false });
    this.props.firebase
      .storage()
      .ref("Reports")
      .child(filename)
      .getDownloadURL()
      .then((url) => this.setState({ url: url }));

    toast.success("Success Notification !", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });

    //console.log('reportfun',this.state)
  };

  handleSubmit = (e) => {
    e.preventDefault();
    //console.log(this.state);
    const { name, details, img, url, order } = this.state;

    const newState = {
      name: name,
      details: details,
      img: img,
      url: url,
      order: order,
    };
    this.props.editReport(newState, this.props.location.state.id);
    this.props.history.push("/Reports");
  };

  setNewState = (report) => {
    const state = this.state;
    state.name = report.name;
    state.details = report.details;
    state.img = report.img;
    state.url = report.url;
    state.order = report.order;
    this.setState(state);
    //console.log(state)
  };

  render() {
    const { auth, profile, Provinces, jobs, reportX } = this.props;
    const { name, details, img, url } = this.state;
    //console.log(reportX)

    if (!profile.role == "admin") return <Redirect to="/signin" />;
    return (
      <section class="header-content products p-info-section ">
        <div className="container">
          <ToastContainer autoClose={2000} />

          <form onSubmit={this.handleSubmit}>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                name="name"
                value={name}
                onChange={this.onChange}
                placeholder="Name"
              />
            </div>

            <div class="form-group">
              <input
                type="text"
                class="form-control"
                name="details"
                value={details}
                onChange={this.onChange}
                placeholder="details"
              />
            </div>

            <div class="form-group  section">
              <CustomUploadButton
                accept="image/*"
                name="avatar"
                randomizeFilename
                storageRef={this.props.firebase.storage().ref("images")}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
                style={{
                  backgroundColor: "steelblue",
                  color: "white",
                  padding: 5,
                  borderRadius: 4,
                  margin: 5,
                }}
              >
                غلاف التقرير
              </CustomUploadButton>
            </div>
            <div class="form-group  section">
              <CustomUploadButton
                accept="pdf/*"
                name="avatar"
                randomizeFilename
                storageRef={this.props.firebase.storage().ref("Reports")}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccessReport}
                onProgress={this.handleProgress}
                style={{
                  backgroundColor: "steelblue",
                  color: "white",
                  padding: 5,
                  borderRadius: 4,
                  margin: 5,
                }}
              >
                التقرير
              </CustomUploadButton>
            </div>

            <button type="submit" class="btn btn-success right ">
              تعديل
            </button>
          </form>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, props) => {
  //console.log("test",props.state)
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    reportX: state.firestore.data.Reports[props.location.state.id],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editReport: (Report, Id) => dispatch(editReport(Report, Id)),
  };
};

export default compose(
  firestoreConnect((props) => [
    { collection: "Reports", doc: props.location.state.id },
  ]),
  connect(mapStateToProps, mapDispatchToProps)
)(EditReport);
