export const createReports = (report) => {
    return (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();
        const profile = getState().firebase.profile;
        const authorId = getState().firebase.auth.uid;
        if (profile.role == 'admin') {
            firestore.collection('Reports').add({
                ...report,
            }).then(() => {
                dispatch({ type: 'CREATE_PROJECT_SUCCESS' });
            }).catch(err => {
                dispatch({ type: 'CREATE_PROJECT_ERROR' }, err);
            });
        }




    }
};

export const editReport = (report, id) => {

    return (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();
        const profile = getState().firebase.profile;
        const authorId = getState().firebase.auth.uid;

        if (profile.role == 'admin') {
            firestore.collection('Reports').doc(id).set({
                ...report,
            }).then(() => {
                dispatch({ type: 'EDIT_PROJECT_SUCCESS' });
            }).catch(err => {
                dispatch({ type: 'EDIT_PROJECT_ERROR' }, err);
            });
        }


    }
};