import React from 'react'
import { NavLink } from 'react-router-dom'

const SignedOutLinks = () => {
  return (
  


    <ul style={{ direction: "rtl"}}>
      {/* <li><NavLink to='/create'>شهيد جديد</NavLink></li> */}
      <li name="Home"><a><NavLink to="/" className="navLink homeMenu">الرئيسية</NavLink></a></li>
      <li name="Books"><a><NavLink to="/Books" className="navLink">الكتب</NavLink></a></li>
      <li name="Reports"><a><NavLink to="/Reports" className="navLink">تقارير</NavLink></a></li>
      <li name="Videos"><a><NavLink to="/Videos" className="navLink">فيديو</NavLink></a></li>
      <li name="Martyrs"><a><NavLink to="/Dashboard" className="navLink">الشهداء</NavLink></a></li>
    
      <li style={{ float: "left",marginRight:10 }}><a><NavLink to='/signin'className="navLink" >  دخول  </NavLink></a></li>
      <li style={{ float: "left", marginRight: 10 }}><a><NavLink to='/signup' className="navLink">  تسجيل </NavLink></a></li>


      {/* <li><NavLink to='/' className="btn btn-floating pink lighten-1">
          {props.profile.initials}
        </NavLink></li> */}
    </ul>
 
  )
}

export default SignedOutLinks

      // <ul className="">
      //   <li><a><NavLink to='/signup'>حساب جديد</NavLink></a></li>
      //   <li><a><NavLink to='/signin'>تسجيل الدخول</NavLink></a></li>
      // </ul>