import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect, Link } from "react-router-dom";
import { Document, Page } from "react-pdf";

const VideoDetails = (props) => {
  const { video, auth, profile } = props;
  //console.log('martyr1', video)
  // if (!auth.uid) return <Redirect to='/signin' />
  if (video) {
    return (
      <div className="">
        {/* <section class="header-content products p-info-section ">
          <div class="container">
            {profile.role == "admin" ? <Link to={{
              pathname: '/EditVideo/' + props.match.params.id,
              state: {
                id: props.match.params.id,
              }
            }}  >تعديل</Link> : ''}

          </div>
        </section> */}

        <section class="products">
          <div class="container">
            <div>
              <br></br>
            </div>
            <div className="section  red">
              <div className="ar-text">{video.name}</div>
            </div>
            <div className="section  red">
              {profile.role == "admin" ? (
                <Link
                  to={{
                    pathname: "/EditVideo/" + props.match.params.id,
                    state: {
                      id: props.match.params.id,
                    },
                  }}
                >
                  تعديل
                </Link>
              ) : (
                ""
              )}
            </div>
            <object
              width="100%"
              height="800"
              data={video.url}
              type="application/pdf"
            >
              {" "}
            </object>
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div className="container ">
        <p>Loading Data...</p>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  //console.log('state', state);
  //console.log('ownProps', ownProps);
  const id = ownProps.match.params.id;
  const Videos = state.firestore.data.Videos;
  const video = Videos ? Videos[id] : null;
  return {
    video: video,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    {
      collection: "Videos",
    },
  ])
)(VideoDetails);
