import React, { Component } from "react";
import VideosList from "../Videos/VideosList";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect, Link } from "react-router-dom";

import Button from "react-bootstrap/Button";

class Videos extends Component {
  render() {
    const {
      Videos,

      profile,

    } = this.props;
    //console.log("booook", Videos)

    // if (!auth.uid) return <Redirect to='/signin' />

    return (
      <div className="">
        <section className="header-content dash-container-gray">
          <div className="container-fluid">
            <div className="dashboard container">
              <div className="dash-container-gray">
                {profile.role == "admin" ? (
                  <Link
                    className="btn pink lighten-1 right "
                    to={{
                      pathname: "/CreateVideos",
                    }}
                  >
                    <Button variant="info">إضافة الفيديو</Button>
                  </Link>
                ) : (
                  ""
                )}
              </div>

              <div className="">
                {/* col s12 m6 */}
                <div className="container">
                  <VideosList
                    videos={Videos}
                    props={this.props}
                    admin={profile.role == "admin" ? true : false}
                  />
                </div>
                {/* <div className="col s12 m5 offset-m1">
            <Notifications notifications={notifications} />
          </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    boards: state.firestore.ordered.boards,
    Videos: state.firestore.ordered.Videos,
    auth: state.firebase.auth,
    notifications: state.firestore.ordered.notifications,
    profile: state.firebase.profile,
  };
};

export default compose(
  firestoreConnect(() => [{ collection: "Videos", orderBy: ["order"] }]),
  connect(mapStateToProps)
)(Videos);
