import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'

class SignIn extends Component {
  state = {
    email: '',
    password: ''
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signIn(this.state)
  }
  render() {
    const { authError, auth } = this.props;
    if (auth.uid) return <Redirect to='/' /> 
    return (
         <section class="header-content products p-info-section  ">
      <div className="container">
        <form  onSubmit={this.handleSubmit}>
          <h4 className="h4">تسجيل الدخول</h4>
          <div className="form-group">
            {/* <label htmlFor="email">البريد الالكتروني</label> */}
            <input className="form-control" type="email" id='email' placeholder="البريد الالكتروني" onChange={this.handleChange} />
          </div>
          <div className="form-group">
            {/* <label htmlFor="password">الرقم السري</label> */}
            <input className="form-control" type="password" id='password' placeholder="الرقم السري" onChange={this.handleChange} />
          </div>
          <div className="form-group ">
            <button className="btn pink lighten-1 z-depth-0 ">تسجيل الدخول</button>
            <div className=" red-text">
              {/* { authError ? <p>{authError}</p> : null } */}
              { authError ? <p className="alert">يرجى إدخال البيانات</p> : null }
            </div>
          </div>
        </form>
      </div>
      </section>
    )
  }
}

const mapStateToProps = (state) => {
  return{
    authError: state.auth.authError,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
