import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect, Link } from "react-router-dom";
import moment from "moment";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";
import Slider from "react-slick";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Swiper from "react-id-swiper";
import cx from "clsx";

import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";

import Button from "@material-ui/core/Button";
import TextInfoCardContent from "@mui-treasury/components/cardContent/textInfo";
import { useBlogCardContentStyles } from "@mui-treasury/styles/cardContent/blog";
import { useOverShadowStyles } from "@mui-treasury/styles/shadow/over";
import Divider from "@material-ui/core/Divider";
import StyledButton from "react-bootstrap/Button";
import Icon from "@material-ui/core/Icon";

const MartyrsDetails = (props) => {
  const { martyr, auth, profile, Books } = props;
  //console.log("Books", Books);
  // if (!auth.uid) return <Redirect to='/signin' />

  var settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: "#000",
      backgroundColor: " #ffffff00",
      fontFamily: "Aref Ruqaa",
      fontSize: "47px",
    },
    elevation1: {
      boxShadow: "0px 0px 0px 0px",
    },
  }));

  const useStylesProfile = makeStyles(({ breakpoints, spacing }) => ({
    root: {
      margin: "auto",
      borderRadius: spacing(2), // 16px
      transition: "0.3s",
      boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
      position: "relative",
      maxWidth: 900,
      marginLeft: "auto",
      overflow: "initial",
      background: "#c6c4c5bd",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "0px",

      [breakpoints.up("md")]: {
        flexDirection: "row",
        paddingTop: spacing(2),
      },
    },
    media: {
      width: "88%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: spacing(-3),
      height: 0,
      paddingBottom: "48%",
      borderRadius: spacing(2),
      backgroundColor: "#fff",
      backgroundPosition: "inherit",
      position: "relative",

      [breakpoints.up("md")]: {
        width: "80%",
        marginLeft: spacing(-3),
        marginTop: 0,
        transform: "translateX(-8px)",
      },
      "&:after": {
        content: '" "',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundImage: "linear-gradient(147deg, #fe8a39 0%, #fd3838 74%)",
        borderRadius: spacing(2), // 16
        opacity: 0.5,
      },
    },
    content: {
      padding: 0,

      root: {
        padding: 0,
      },
    },
    cta: {
      marginTop: 24,

      textTransform: "initial",
    },
  }));
  const classes = useStyles();
  const classesProfile = useStylesProfile();
  const {
    button: buttonStyles,
    ...cardContentStyles
  } = useBlogCardContentStyles();
  const shadowStyles = useOverShadowStyles();
  const params = {
    direction: "vertical",
    slidesPerView: 1,
    spaceBetween: 30,
    mousewheel: true,
    effect: "fade",
    loop: true,
    containerClass: "blog-slider__wrp",
    slideClass: "blog-slider__item",
    pagination: {
      el: ".blog-slider__pagination ",
      clickable: true,
    },
  };

  if (martyr) {
    return (
      <div className="details">
        <section class="header-content products p-info-section ">
          <Card className={cx(classesProfile.root, shadowStyles.root)}>
            <CardContent>
              {/* <TextInfoCardContent
                classes={cardContentStyles}
                overline={martyr.name}
                heading={martyr.martyr_name
                  + martyr.martyr_date}
               
                body={
                  martyr.residence}
              /> */}
              <h4 className="TextInfo-overline-158">{martyr.name}</h4>
              <table className="pInfo table-bordered table table-condensed">
                <tbody>
                  <tr>
                    <td className="pInfo-td">السن</td>
                    <td className="pInfo-td">{martyr.age ? martyr.age : ""}</td>
                  </tr>
                  <tr>
                    <td className="pInfo-td">المهنة</td>
                    <td className="pInfo-td">
                      {martyr.jobDetails ? martyr.jobDetails : ""}
                    </td>
                  </tr>

                  <tr>
                    <td className="pInfo-td">الحالة الاجتماعية</td>
                    <td className="pInfo-td">
                      {martyr.Social_status ? martyr.Social_status : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="pInfo-td">محل الإقامة</td>
                    <td className="pInfo-td">
                      {martyr.residence ? martyr.residence : ""}
                    </td>
                  </tr>

                  <tr>
                    <td className="pInfo-td">تاريخ الاستشهاد</td>
                    <td className="pInfo-td">
                      {martyr.martyr_name ? martyr.martyr_name : ""}{" "}
                      {martyr.martyr_date ? martyr.martyr_date : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardContent>

            <CardMedia
              className={classesProfile.media}
              image={martyr.personal_photo ? martyr.personal_photo : ""}
            />
          </Card>

          <div class="btn">
            {profile.role == "admin" ? (
              <Link
                to={{
                  pathname: "/EditMartyrs/" + props.match.params.id,
                  state: {
                    id: props.match.params.id,
                  },
                }}
              >
                <StyledButton variant="info"> تعديل</StyledButton>
              </Link>
            ) : (
              ""
            )}
          </div>
          {/*  martyr.complete_status ==> check if data completed or not, old data don't have status, new data status== false need to complete */}
          {martyr.complete_status == false ? (
            <div>
              <StyledButton variant="primary">
                <p style={{ fontSize: 25 }}>
                  بيانات الشهيد تحت الاستكمال .. شارك معنا
                </p>
                <hr></hr>
                <div>
                  {" "}
                  <Icon
                    className="fa fa-angle-double-down"
                    style={{ color: "#fff", fontSize: 30 }}
                  />
                </div>
              </StyledButton>
            </div>
          ) : (
            <div></div>
          )}
        </section>
        {martyr.rawState ? (
          <section class="products profile-content" id="content">
            <div class="container">
              <Editor
                toolbarHidden
                editorState={EditorState.createWithContent(
                  convertFromRaw(martyr.rawState)
                )}
                readOnly
              />
            </div>
          </section>
        ) : (
          <div></div>
        )}
        {martyr.books ? (
          <div id="books" class="products books">
            {" "}
            <div class="container">
              <div className={classes.root}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper elevation={0} className={classes.paper}>
                      كتب عن الشهيد
                    </Paper>
                  </Grid>
                </Grid>
              </div>
              <Slider {...settings}>
                {Object.values(martyr.books).map((book) => (
                  <div
                    onClick={(e) => {
                      window.open(book.url, "", "fullscreen=0");
                    }}
                  >
                    <img
                      className="img-book"
                      src={book.img}
                      style={{ cursor: "pointer" }}
                    ></img>
                  </div>
                ))}
              </Slider>{" "}
            </div>
          </div>
        ) : null}
      </div>
    );
  } else {
    return (
      <div className="loading ">
        <p> ...جاري تحميل البيانات </p>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  //console.log("state", state);
  //console.log("ownProps", ownProps);
  const id = ownProps.match.params.id;
  const martyrs = state.firestore.data.boards;
  const martyr = martyrs ? martyrs[id] : null;
  //console.log("martyr", martyr);
  // const books=martyr['books'] ? state.firestore.data.Books.map(book=>book.id) :null
  return {
    martyr: martyr,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((ownProps) => [
    {
      collection: "boards",
      doc: ownProps.match.params.id,
    },
  ])
)(MartyrsDetails);
