export const createMartyrs = (martyr) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const profile = getState().firebase.profile;
    if (profile.role == "admin") {
      // firestore.collection('boards').add({
      //   ...martyr,
      // }).then(() => {
      //   dispatch({ type: 'CREATE_PROJECT_SUCCESS' });
      //   //console.log(martyr)
      // }).catch(err => {
      //   dispatch({ type: 'CREATE_PROJECT_ERROR' }, err);
      // });

      let name = martyr.name.toString();
      //console.log(name);
      firestore.collection("jobs").doc(martyr.job).set({
        description: martyr.job,
      });
      firestore
        .collection("martyrNames")
        .doc(name)
        .set({
          name: martyr.name,
        })
        .then(() => {
          firestore
            .collection("boards")
            .add({
              ...martyr,
            })
            .then((docRef) => {
              dispatch({ type: "CREATE_PROJECT_SUCCESS" });
              //console.log('id=',docRef.id)
              firebase
                .database()
                .ref("boards/" + docRef.id)
                .set({
                  name: martyr.name,
                  age: martyr.age,
                  Social_status: martyr.Social_status,
                  residence: martyr.residence,
                  martyr_date: martyr.martyr_date,
                  job: martyr.job,
                  jobDetails: martyr.jobDetails,
                  Province: martyr.Province,
                  martyr_name: martyr.martyr_name,
                  personal_photo: martyr.personal_photo,
                  books: martyr.books,
                });
            })
            .catch((err) => {
              dispatch({ type: "CREATE_PROJECT_ERROR_boards" }, err);
            });

          dispatch({ type: "CREATE_PROJECT_SUCCESS" });
        })
        .catch((err) => {
          dispatch({ type: "CREATE_PROJECT_ERROR_Name" }, err);
        });
    }

    // firestore.collection('boards').add({
    //   ...martyr,
    //   authorFirstName: profile.firstName,
    //   authorLastName: profile.lastName,
    //   authorId: authorId,
    //   createdAt: new Date()
    // }).then(() => {
    //   dispatch({ type: 'CREATE_PROJECT_SUCCESS' });
    // }).catch(err => {
    //   dispatch({ type: 'CREATE_PROJECT_ERROR' }, err);
    // });
  };
};
export const sendMartyrs = (martyr) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .database()
      .ref("Emails/" + Date.now())
      .set({
        ...martyr,
      })
      .then(() => {
        dispatch({ type: "SEND_MARTYR_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "SEND_MARTYR_ERROR" }, err);
      });
  };
};

export const editMartyrs = (martyr, id) => {
  //console.log('myId', id)
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;
    //console.log("martyrfirebase", martyr)
    if (profile.role == "admin") {
      firestore
        .collection("boards")
        .doc(id)
        .set({
          ...martyr,
        })
        .then(() => {
          dispatch({ type: "EDIT_PROJECT_SUCCESS" });

          firebase
            .database()
            .ref("boards/" + id)
            .set({
              name: martyr.name,
              age: martyr.age,
              Social_status: martyr.Social_status,
              residence: martyr.residence,
              martyr_date: martyr.martyr_date,
              job: martyr.job,
              jobDetails: martyr.jobDetails,
              Province: martyr.Province,
              martyr_name: martyr.martyr_name,
              personal_photo: martyr.personal_photo,
              books: martyr.books,
            });
        })
        .catch((err) => {
          dispatch({ type: "EDIT_PROJECT_ERROR" }, err);
        });
    }
  };
};
