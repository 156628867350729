import React from "react";
// import ProjectSummary from './ProjectSummary'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import CustomToolbar from "./CustomToolbar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const MartyrsList = ({ props, martyrs }) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const labels = xs ? "26% !important" : "50%";
  //console.log("xs", xs)
  const columns = xs
    ? ["", "", "", ""]
    : ["الاسم", "المهنة", "المحافظة", "حادثة الاستشهاد"];
  const options = {
    filterType: "dropdown",
    onRowClick: (rowData, rowState) => {
      //  //console.log(rowData, rowState);
      this.seletctItem(rowState.dataIndex);
    },
  };
  //custom UI for DataTable
  //MUIDataTableBodyCell-cellHide-2044
  let getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            fontSize: "2rem",
            textAlign: "right",
            fontFamily: "Cairo, sans- serif",
          },
          // to hide labels when screen xs
          cellHide: {
            width: labels,
          },
        },
        MuiChip: {
          label: {
            fontSize: "14px",
          },
        },

        MuiTable: {
          root: {
            direction: "rtl",
          },
        },
        MuiPaper: {
          root: {
            backgroundColor: "#FEEA9B",
          },
        },
        MuiTableCell: {
          head: {
            fontSize: "2rem",
            textAlign: "right",
            fontFamily: "Cairo, sans- serif",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "#FFFFFF",
            },
          },
        },
        MUIDataTableToolbar: {
          actions: {
            textAlign: "right",
          },
        },

        tooltip: {
          fontSize: "20px",
        },

        MuiSvgIcon: {
          root: {
            fontSize: "2.5rem",
          },
        },
        MuiTypography: {
          body1: {
            fontSize: "2rem",
          },
        },
        MuiInputBase: {
          root: {
            fontSize: "2rem",
          },
        },
        MuiFormLabel: {
          root: {
            fontSize: "2rem",
          },
        },
        MUIDataTableFilter: {
          resetLink: {
            fontSize: "18px",
          },
          title: {
            fontSize: "18px",
          },
          MUIDataTableFilterList: {
            chip: {},
          },
        },
      },
    });

  let data = [];

  return (
    <div className="">
      {/* reverse to get the old complete data first  */}
      {martyrs &&
        martyrs.reverse().map((martyr) => {
          let e = [];

          e.push(martyr.value.name);
          e.push(martyr.value.job);
          e.push(martyr.value.Province);
          e.push(martyr.value.martyr_name_short);
          e.push(martyr.key);

          data.push(e);
        })}
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={""}
          data={data}
          columns={columns}
          options={{
            filterType: "multiselect",

            // customToolbar: () => {
            //   return (
            //     <CustomToolbar />
            //   );
            // },
            selectableRows: false,
            searchPlaceholder: "ابحث عن شهيد",

            onRowClick: (rowData, rowState) => {
              //    //console.log("rowData",rowData);
              //   //console.log("rowState",rowState);
              //   //console.log(data[rowState['dataIndex']])

              //  //console.log(martyrs[rowState['dataIndex']])
              //   //console.log(props)

              props.history.push({
                pathname:
                  "/MartyrsDetails/" + martyrs[rowState["dataIndex"]].key,
              });
            },
            textLabels: {
              body: {
                noMatch: "جاري تحميل البيانات ...",
                toolTip: "تصنيف",
                columnHeaderTooltip: (column) => `Sort for ${column.label}`,
              },
              pagination: {
                next: "الصفحة التالية",
                previous: "الصفحة السابقة",
                rowsPerPage: "عدد الشهداء في الصفحة",
                displayRows: "من",
              },

              toolbar: {
                search: "بحث",
                downloadCsv: "تحميل CSV",
                print: "طباعة",
                viewColumns: "مشاهدة أعمدة",
                filterTable: "التصنيفات",
              },
              filter: {
                all: "الكل",
                title: "التصنيفات",
                reset: "الجميع",
              },
              viewColumns: {
                title: "مشاهدة",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "row(s) selected",
                delete: "Delete",
                deleteAria: "Delete Selected Rows",
              },
            },
          }}
        />
      </MuiThemeProvider>
    </div>
  );
};

export default MartyrsList;
