import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import ContactForm from './ContactForm';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        justifyItems: "center",
        justifyContent: "center",
        margin:0,



    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: "#000",
        backgroundColor: " #ffffff00",
        fontFamily: "Aref Ruqaa",
        fontSize: "47px",
       alignContent:"center",
       justifyContent:"center",
       margin:0,



    },
    paper2:{
        borderLeft:"4px solid white",
        borderRight:"4px solid white",
        padding: theme.spacing(2),
  
        textAlign: 'center',
        color: "#fff",
        backgroundColor: " #ffffff00",
        fontFamily: "Aref Ruqaa",
        fontSize: "180%",
        alignContent:"center",
        justifyContent: "center",
        margin:0,
    },
    elevation1: {
        boxShadow: '0px 0px 0px 0px'

    },

}));

const Footer = () => {
    const classes = useStyles();
 
    return (


        <footer >

            <div class="container-fluid">
                <div className={classes.root}>
                    <Grid container spacing={0} justify="space-around"
                        alignItems="center">
                        <Grid item  sm={3} xs={12}>
                            <Paper elevation={0} className={classes.paper}>
                            <img className="c-logo" src="/../img/LightLogo.png" alt=""/>
                                    <p class="contact-us">تواصل معنا:</p>
                                <div class="mail-container">

                                  
                                        <a target="_blank" href=" mailto:<rabaaahrar@gmail.com>rabaaahrar@gmail.com?" class="contact-us">RabaaAhrar@gmail.com</a>
                                       
                                  
                              </div>
                                </Paper>

                        </Grid>
                        <Grid item  sm={4} xs={12}>
                            <Paper elevation={0} className={classes.paper, classes.paper2}>
                                <ContactForm/>
</Paper>
</Grid>
                        <Grid item  sm={3} xs={8}>
                            <Paper elevation={0} className={classes.paper}>
                                <div class="icon-bar">
                                  
                                    <a target="_blank" href="https://twitter.com/RabaaAhrar" class="twitter"><i class="fa fa-twitter"></i></a>
                                    <a target="_blank" href="https://www.instagram.com/ahrar.rabaa/" class="instagram"><i class="fa fa-instagram"></i></a>
                                     <a target="_blank" href=" mailto:<rabaaahrar>rabaaahrar@gmail.com?" class="google"><i class="fa fa-google"></i></a>
                                    <a target="_blank" href="https://www.facebook.com/Ahrar-Rabaa-%D8%A3%D8%AD%D8%B1%D8%A7%D8%B1-%D8%B1%D8%A7%D8%A8%D8%B9%D8%A9-101888821630941" class="facebook"><i class="fa fa-facebook"></i></a>
                                </div>
                                </Paper>

                        </Grid>
                    </Grid>
                </div>
            </div>
        </footer>
    );

}

export default Footer;