import React, { Component } from "react";

import Modal from "react-modal";

import { sendMartyrs } from "../../store/actions/MartyrActions";
import { connect } from "react-redux";
import { compose } from "redux";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import {toast } from "react-toastify";
import { convertFromRaw } from "draft-js";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
import { firestoreConnect } from "react-redux-firebase";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    maxHeight: "65%",

    transform: "translate(-50%, -50%)",
  },
};

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      name: "",
      age: "",
      job: "",
      jobDetails: "",
      jobs: [],
      Social_status: "",
      Province: "",
      Provinces: [],
      books: [],
      residence: "",
      martyr_date: "",
      martyr_name: "",
      editorState: EditorState.createEmpty(),
      contentState: {},
      personal_photo: "",
      isUploading: false,
      progress: 0,
      uploadingFlag: 0,
    };
  }

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
    //console.log(e);
    //console.log(state);
  };

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = (progress) => this.setState({ progress });
  handleUploadError = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = (filename) => {
    this.setState({ progress: 100, isUploading: false });
    this.props.firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then((url) => this.setState({ personal_photo: url }));

    toast.success("Success Notification !", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });

    //console.log(this.state)
  };

  handleChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };
  onEditorStateChange = (editorState) => {
    //console.log(editorState)
    const state = this.state;
    state.editorState = editorState;
    this.setState(state);
  };

  onContentStateChange = (contentState) => {
    //console.log(contentState)
    this.setState({
      contentState,
    });
  };

  uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      //console.log('Uploading image...');
      let d = new Date();
      const filename = d.getTime();
      this.uploadImage(file, filename)
        .then((link) => {
          resolve({ data: { link } });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  uploadImage = (file, filename) => {
    //console.log('Uploading image...');
    return new Promise((resolve, reject) => {
      if (!file) {
        reject("Invalid file.");
      }
      //  //console.log(file,filename)
      this.props.firebase
        .storage()
        .ref("images/" + filename)
        .put(file)
        .then(function (snapshot) {
          //console.log(snapshot);
          snapshot.ref.getDownloadURL().then((url) => resolve(url));
        });
    });
  };

  onSelect = (selectedList, selectedItem) => {
    let books = [];
    selectedList.map((book) => books.push(book["id"]));

    this.setState({ books: selectedList });
  };

  onRemove = (selectedList, removedItem) => {
    let books = [];
    selectedList.map((book) => books.push(book["id"]));

    this.setState({ books: selectedList });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    //console.log(this.state);
    const {
      name,
      age,
      Social_status,
      residence,
      martyr_date,
      job,
      jobDetails,
      Province,
      books,
      martyr_name,
      personal_photo,
      id,
    } = this.state;
    const rawState = convertToRaw(this.state.editorState.getCurrentContent());

    const newState = {
      name: name,
      age: age,
      Social_status: Social_status,
      residence: residence,
      martyr_date: martyr_date,

      martyr_name: martyr_name,
      personal_photo: personal_photo,
      rawState: rawState,
      books: books,
    };
    this.props.sendMartyrs(newState);
    this.setState({ isOpen: false });
  };

  setNewState = (martyr) => {
    const state = this.state;
    state.name = martyr.name;
    state.age = martyr.age;
    state.job = martyr.job;
    state.jobDetails = martyr.jobDetails;
    state.Social_status = martyr.Social_status;
    state.Province = martyr.Province;
    state.residence = martyr.residence;
    state.martyr_date = martyr.martyr_date;
    state.martyr_name = martyr.martyr_name;
    state.personal_photo = martyr.personal_photo;
    state.editorState = EditorState.createWithContent(
      convertFromRaw(martyr.rawState)
    );
    state.books = martyr.books;

    this.setState(state);
    //console.log(state)
  };

  toggleModal = (event) => {
    //console.log(event);

    this.setState({ isOpen: true });
  };
  toggleModalclose = (event) => {
    //console.log("close", event);

    this.setState({ isOpen: false });
  };

  unRegisteredUser = (event) => {
    NotificationManager.error("يرجى تسجيل الدخول");
  };

  render() {
    const {
      auth,
    } = this.props;
    const {
      name,
      age,
      Social_status,
      residence,
      martyr_date,
      job,
      jobDetails,
      Province,
      martyr_name,
    
      editorState,
      isOpen,
    } = this.state;

    return (
      <div
        onClick={auth.uid ? this.toggleModal : this.unRegisteredUser}
        style={{ cursor: "pointer" }}
      >
        <p>شارك معنا في:</p>
        <p>تعديل بيانات الشهيد</p>
        <p>إضافة شهيد جديد</p>
        <p>إضافة صورة شهيد</p>

        <Modal
          isOpen={isOpen}
          onRequestClose={this.toggleModalclose}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <form className="white" onSubmit={this.handleSubmit}>
            <h1 id="heading">شارك معنا</h1>
            <p>الموقع لازال تحت الانشاء والتحديث</p>

            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="name"
                value={name}
                onChange={this.onChange}
                placeholder="الاسم"
              />
            </div>

            <div className="form-group">
              <input
                type="number"
                className="form-control"
                name="age"
                value={age}
                onChange={this.onChange}
                placeholder="السن"
              />
            </div>

            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="job"
                value={job}
                onChange={this.onChange}
                placeholder="المؤهل"
              />
            </div>

            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="jobDetails"
                value={jobDetails}
                onChange={this.onChange}
                placeholder="الوظيفة"
              />
            </div>

            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="Social_status"
                value={Social_status}
                onChange={this.onChange}
                placeholder="الحالة الاجتماعية"
              />
            </div>

            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="Province"
                value={Province}
                onChange={this.onChange}
                placeholder="المحافظة"
              />
            </div>

            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="residence"
                value={residence}
                onChange={this.onChange}
                placeholder="محل الإقامة"
              />
            </div>

            <div className="form-group">
              <input
                type="date"
                className=" datepicker"
                name="martyr_date"
                value={martyr_date}
                onChange={this.onChange}
                placeholder="تاريخ الاستشهاد"
              />
            </div>

            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="martyr_name"
                value={martyr_name}
                onChange={this.onChange}
                placeholder="موقعة الاستشهاد"
              />
            </div>

            <div className="form-group  section">
              <CustomUploadButton
                accept="image/*"
                name="avatar"
                randomizeFilename
                storageRef={this.props.firebase.storage().ref("images")}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
                style={{
                  backgroundColor: "steelblue",
                  color: "white",
                  padding: 5,
                  borderRadius: 4,
                  margin: 5,
                }}
              >
                إضافة صورة شخصية
              </CustomUploadButton>
            </div>

            <div style={{ backgroundColor: "white" }}>
              <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={this.onEditorStateChange}
                onContentStateChange={this.onContentStateChange}
                toolbar={{
                  image: {
                    className: undefined,
                    component: undefined,
                    popupClassName: undefined,
                    urlEnabled: true,
                    uploadEnabled: true,
                    alignmentEnabled: true,
                    uploadCallback: this.uploadImageCallBack,
                    previewImage: false,
                    inputAccept:
                      "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                    alt: { present: false, mandatory: false },
                    defaultSize: {
                      height: "auto",
                      width: "auto",
                    },
                  },
                }}
              />
            </div>

            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button className="btn pink lighten-1">إرسال</button>
              <button
                className="btn pink lighten-1"
                onClick={this.toggleModalclose}
              >
                تراجع
              </button>
            </div>
          </form>
        </Modal>
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    //boards: state.firestore.ordered.boards,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendMartyrs: (Martyr) => dispatch(sendMartyrs(Martyr)),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(CreateMartyrs)

export default compose(
  firestoreConnect((props) => []),
  connect(mapStateToProps, mapDispatchToProps)
)(ContactForm);
