import React from "react";
// import ProjectSummary from './ProjectSummary'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { compose, spacing, palette, breakpoints } from "@material-ui/system";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const BooksList = ({ props, books, admin }) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const getGridListCols = () => {
    if (lg) {
      return 3;
    }
    if (md) {
      return 2;
    }

    if (sm) {
      return 1;
    }

    return 1;
  };

  //set Grid Height
  const getGridListHeight = () => {
    if (lg) {
      return 600;
    }
    if (md) {
      return 600;
    }

    if (sm) {
      return 450;
    }

    return 300;
  };

  //custom UI for DataTable
  let getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiGridListTile: {
          tile: {
            boxShadow: " 0 4px 8px 0 rgba(0, 0, 0, 0.2)",
          },
          imgFullHeight: {
            left: "63%",
          },
        },
        MuiGridList: {
          root: {
            direction: "rtl",
          },
        },
        MuiGridListTileBar: {
          titleWrapActionPosRight: {
            marginRight: "16px !important",
          },
        },
      },
    });

  let data = [];

  return (
    <div className="container">
      {books &&
        books.map((book) => {
          //    //console.log("martyr", martyr);
          let e = [];
          e.push(book["name"]);
          e.push(book["img"]);
          e.push(book["details"]);
          e.push(book["url"]);
          e.push(book["id"]);
          data.push(e);
          //console.log("data", data);
        })}

      <MuiThemeProvider theme={getMuiTheme()}>
        <GridList
          cellHeight={getGridListHeight()}
          cols={getGridListCols()}
          spacing={20}
        >
          {data.map((tile) => (
            <GridListTile>
              <img
                src={tile[1]}
                alt={tile[1]}
                onClick={() => window.open(tile[3], "", "fullscreen=0")}
                style={{ cursor: "pointer" }}
              />
              {admin ? (
                <GridListTileBar
                  title={tile[0]}
                  subtitle={<span> {tile[2]}</span>}
                  actionIcon={
                    <IconButton
                      aria-label={`info about ${tile.title}`}
                      onClick={() => {
                        props.history.push({
                          pathname: "/EditBook/" + tile[4],
                          state: {
                            id: tile[4],
                          },
                        });
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  }
                />
              ) : (
                <GridListTileBar
                  title={tile[0]}
                  subtitle={<span> {tile[2]}</span>}
                  onClick={() => window.open(tile[3], "", "fullscreen=0")}
                />
              )}
            </GridListTile>
          ))}
        </GridList>
      </MuiThemeProvider>
    </div>
  );
};

export default withWidth()(BooksList);
{
  /* <GridListTile>

<img src={tile[1]} alt={tile[1]} onClick={() => props.history.push({
  pathname: '/BookDetails/' + tile[4],
})} style={{ cursor: 'pointer' }} />
<GridListTileBar
  title={tile[0]}
  subtitle={<span> {tile[2]}</span>}
  onClick={() => props.history.push({
    pathname: '/BookDetails/' + tile[4],
  })}

/>

</GridListTile> */
}
