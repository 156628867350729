import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'


const SignedInLinks = (props) => {
  return (

    <ul style={{direction:"rtl"}}>
      {/* <li><NavLink to='/create'>شهيد جديد</NavLink></li> */}
      <li name="Home"><a><NavLink to="/" className="navLink homeMenu">الرئيسية</NavLink></a></li>
      <li name="Books"><a><NavLink to="/Books" className="navLink">الكتب</NavLink></a></li>
      <li name="Reports"><a><NavLink to="/Reports" className="navLink">تقارير</NavLink></a></li>
      <li name="Videos"><a><NavLink to="/Videos" className="navLink">فيديو</NavLink></a></li>
      <li name="Martyrs"><a><NavLink to="/Dashboard" className="navLink">الشهداء</NavLink></a></li>
      <li style={{ float: "left", marginRight: 10 }}><a onClick={props.signOut}><NavLink to='/' className="navLink">  خروج </NavLink></a></li>
      {/* <li style={{ float: "left" }}><a onClick={props.signOut} >خروج</a></li> */}

      {/* <li><NavLink to='/' className="btn btn-floating pink lighten-1">
          {props.profile.initials}
        </NavLink></li> */}
    </ul>

  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(null, mapDispatchToProps)(SignedInLinks)
