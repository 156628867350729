import React, { Component } from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Swiper from 'react-id-swiper';
// Version >= 2.4.0

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase'
import * as alasql from 'alasql';
// import {SectionsContainer, Section} from 'react-fullpage';


import Slider from "react-slick";
import { Switch } from 'react-router-dom';
import Footer from '../../components/layout/Footer'
import 'swiper/css/swiper.css';


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
};
const useStyles = theme => ({
    root: {
        flexGrow: 1,


    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: "#000",
        backgroundColor: " #ffffff00",
        fontFamily: "Cairo",
        fontSize: "24px"


    },
    elevation1: {
        boxShadow: '0px 0px 0px 0px'

    }

});



class Home extends Component {
    componentDidMount() {

    }



    render() {



        var settings = {
            dots: false,
            infinite: true,
            arrows:false,
            autoplay:true,
            speed: 500,
            autoplaySpeed: 4500,
            accessibility:false,
            slidesToShow: 4,
            slidesToScroll: 3,
        
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 3,
                   
                       
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                       
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        };

        const params = {
            effect: 'coverflow',
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: 'auto',

            coverflowEffect: {
                rotate: 90,
                stretch: 0,
                depth: 500,
                modifier: 1,
                slideShadows: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                color: '#fff'
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 5
                },
                // when window width is >= 480px
                480: {
                    slidesPerView: 1,
                    spaceBetween: 5
                },
                // when window width is >= 640px
                800: {
                    slidesPerView: 2,
                    spaceBetween: -120,

                }
            },

            //direction: 'horizontal',
            freeMode: true,
            // mousewheel: true,

            loopFillGroupWithBlank: true,
            loop: true,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false
            },
        };

        let options = {
            sectionClassName: 'section',
            anchors: ['gallery', 'statistics#1', 'statistics#1', 'books'],
            scrollBar: false,
            navigation: false,
            verticalAlign: false,
            sectionPaddingTop: '50px',
            sectionPaddingBottom: '50px',
            arrowNavigation: true
        };
        let Provinces = {
            "الإسكندرية": 0,
            "الغربية": 0,
            "الجيزة": 0,
            "الإسماعيلية": 0,
            "كفر الشيخ": 0,
            "مطروح": 0,
            "المنيا": 0,
            "المنوفية": 0,
            "الوادي الجديد": 0,
            "شمال سيناء": 0,
            "بورسعيد": 0,
            "أسوان": 0,
            "القليوبية": 0,
            "قنا": 0,
            "البحر الأحمر": 0,
            "الشرقية": 0,
            "سوهاج": 0,
            "جنوب سيناء": 0,
            "السويس": 0,
            "الأقصر": 0,
            "أسيوط": 0,
            "البحيرة": 0,
            "بني سويف": 0,
            "القاهرة": 0,
            "الدقهلية": 0,
            "دمياط": 0,
            "الفيوم": 0,
        };
        let Ages = null//child,youth,man,old,total,women
        const classes = this.props.classes;
        const { boards, home,Books } = this.props;


        // let ProvinceStat
        //  if boards != null ?  : null
        if (boards != null) {
            //   ProvinceStat = alasql('SELECT COUNT(value) AS firstnames FROM ? ', [boards])
            //  ProvinceStat = this.alaPluck(boards, [])
            Ages = [0, 0, 0, 0, 0, 0, 0];
            boards.forEach(martyr => {
                Provinces[martyr.value.Province]++
                let age = Number(martyr.value.age);



                switch (true) {
                    case age < 14 && age > 0:
                        Ages[0]++;
                        break;
                    case age > 14 && age < 30:
                        Ages[1]++;
                        break;
                    case age > 30 && age < 45:
                        Ages[2]++;
                        break;
                    case age > 45 && age < 100:
                        Ages[3]++;
                        break;

                }

                Ages[4]++;
                martyr.value.gender === "ذكر" ? Ages[5] += 1 : Ages[6] += 1;
            });

        }


        return (

            <div>

                <section className="header-content">
                    <div className="">
                        {   //home slider photos
                            home ?
                                <Swiper {...params}>
                                    {
                                        Object.values(home['Slider']).map((value) => <div className="img-slider" style={{ backgroundImage: `url(${value})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}></div>)

                                    }
                                </Swiper> : <div>

                                <Swiper {...params}>
                                    {
                                        <div className="img-slider" style={{ backgroundImage: `url()`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                    }
                                </Swiper> 
                                </div>
                        }
                    </div>
                    <div className="slog-div">
                        <a href="/" className="logo slogan"><img src="/img/slogan.png" alt="" /></a>
                    </div>
                </section>



                <section className="products">

                    <div className="container">
                        <div className={classes.root}>

                            {Ages ?
                                <Grid container spacing={3} alignContent='center' justify='center' >
                                    <Grid item xs={6} sm={3}>
                                        <Paper elevation={0} className={classes.paper}>
                                            <CircularProgressbarWithChildren value={44}
                                                strokeWidth={5}>
                                                <div className='box-wave'>
                                                    <div className='wave -one'></div>
                                                    <div className='wave -two'></div>
                                                    <div className='wave -three'></div>
                                                    <div className='title-wave'> رجال {Ages[2]}</div>

                                                </div>
                                            </CircularProgressbarWithChildren>

                                        </Paper>

                                    </Grid>
                                    {/* <Grid item xs={6} sm={3}>
                                        <Paper elevation={0} className={classes.paper}>
                                            <CircularProgressbarWithChildren value={44}
                                                strokeWidth={5}>
                                                <div className='box-wave'>
                                                    <div className='wave -one'></div>
                                                    <div className='wave -two'></div>
                                                    <div className='wave -three'></div>
                                                    <div className='title-wave'>{Ages[0]} طفل</div>

                                                </div>
                                            </CircularProgressbarWithChildren>
                                        </Paper>
                                    </Grid> */}
                                    <Grid item xs={6} sm={3}>
                                        <Paper elevation={0} className={classes.paper}>
                                            <CircularProgressbarWithChildren value={44}
                                                strokeWidth={5}>
                                                <div className='box-wave'>
                                                    <div className='wave -one'></div>
                                                    <div className='wave -two'></div>
                                                    <div className='wave -three'></div>
                                                    <div className='title-wave'>{Ages[3]} شيخ</div>

                                                </div>
                                            </CircularProgressbarWithChildren>

                                        </Paper>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Paper elevation={0} className={classes.paper}>
                                            <CircularProgressbarWithChildren value={44}
                                                strokeWidth={5}>
                                                <div className='box-wave'>
                                                    <div className='wave -one'></div>
                                                    <div className='wave -two'></div>
                                                    <div className='wave -three'></div>
                                                    <div className='title-wave'>{Ages[1]} شاب</div>

                                                </div>
                                            </CircularProgressbarWithChildren>

                                        </Paper>
                                    </Grid>
                                    {/* <Grid item xs={6} sm={3}>
                                        <Paper elevation={0} className={classes.paper}>
                                            <CircularProgressbarWithChildren value={44}
                                                strokeWidth={5}>
                                                <div className='box-wave'>
                                                    <div className='wave -one'></div>
                                                    <div className='wave -two'></div>
                                                    <div className='wave -three'></div>
                                                    <div className='title-wave'>{Ages[5]} ذكر</div>

                                                </div>
                                            </CircularProgressbarWithChildren>

                                        </Paper>
                                    </Grid> */}

                                    <Grid item xs={6} sm={3}>
                                        <Paper elevation={0} className={classes.paper}>
                                            <CircularProgressbarWithChildren value={44}
                                                strokeWidth={5}>
                                                <div className='box-wave'>
                                                    <div className='wave -one'></div>
                                                    <div className='wave -two'></div>
                                                    <div className='wave -three'></div>
                                                    <div className='title-wave'>{Ages[6]} شهيدات</div>

                                                </div>
                                            </CircularProgressbarWithChildren>

                                        </Paper>
                                    </Grid>
                                    <Grid item xs={6} sm={3} >
                                        <Paper elevation={0} className={classes.paper}>
                                            <CircularProgressbarWithChildren value={44}
                                                strokeWidth={5}>
                                                <div className='box-wave'>
                                                    <div className='wave -one'></div>
                                                    <div className='wave -two'></div>
                                                    <div className='wave -three'></div>
                                                    <div className='title-wave'>{Ages[4]} شهيد</div>

                                                </div>
                                            </CircularProgressbarWithChildren>

                                        </Paper>
                                    </Grid>


                                </Grid>

                                : null}
                        </div>
                    </div>
                </section>



                <section className="products statistics">

                    <div className="container">
                        <div className={classes.root}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Paper elevation={0} className={classes.paper}>إحصائية المحافظات</Paper>
                                </Grid>
                                {
                                    Object.keys(Provinces).map((key) =>
                                        !Provinces[key] ? null :
                                            <Grid item xs={6} sm={4} md={3}>
                                                <Paper elevation={0} className={classes.paper}>
                                                    {key}
                                                    <div>
                                                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", fontSize: "17px",marginTop: "6px",}}>
                                                            <div style={{ position: "absolute",marginTop:"9px" }}>{Provinces[key]}</div>
                                                        </div>
                                                        <ProgressBar variant='danger' now={Provinces[key]/2}  />
                                                    </div>



                                                </Paper>

                                            </Grid>

                                    )

                                }






                            </Grid>
                        </div>

                    </div>
                </section>



                <section style={{ padding: 0 }}>


                    <div class="container-fluid products books-home">
                        <div className={classes.root}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Paper elevation={0} className={classes.paper}></Paper>
                                </Grid>
                            </Grid>
                        </div>
                        {
                            home ?
                                <Slider {...settings}>
                                    {
                                        Object.values(Books).map((value) => <div onClick={(e) => { window.open(value['url'], "", "fullscreen=0"); }}><img className="img-book" src={value['img']}></img></div>)



                                    }
                                </Slider> : <div></div>
                        }
                    </div>

                </section>

            </div>

        );
    }
}



const mapStateToProps = (state) => {

    return {

        boards: state.firebase.ordered.boards,
        home: state.firebase.data.Home,
        Books: state.firestore.ordered.Books,


    }
}



export default compose(
    firebaseConnect(() => [
        { path: '/boards', queryParams: ['orderByValue'] },

        { path: '/Home', queryParams: ['orderByValue'] },



    ]),
    firestoreConnect(() => [
        { collection: 'Books', orderBy: ['order'] },
    ]),

    connect(mapStateToProps),
    withStyles(useStyles)
)(Home)
