import React, { Component } from "react";
import BooksList from "../Books/BooksList";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect, Link } from "react-router-dom";

import Button from "react-bootstrap/Button";

class Books extends Component {
  render() {
    const {
      Books,
      auth,
      notifications,
      profile,
      Provinces,
      jobs,
      storage,
    } = this.props;
    //console.log("booook", Books)

    // if (!auth.uid) return <Redirect to='/signin' />

    return (
      <div className="">
        <section className="header-content dash-container-gray">
          <div className="container-fluid">
            <div className="dashboard container">
              <div className="dash-container-gray">
                {profile.role == "admin" ? (
                  <Link
                    className="btn pink lighten-1 right "
                    to={{
                      pathname: "/CreateBooks",
                    }}
                  >
                    <Button variant="info">إضافة كتاب</Button>
                  </Link>
                ) : (
                  ""
                )}
              </div>

              <div className="">
                {/* col s12 m6 */}
                <div className="container">
                  <BooksList
                    books={Books}
                    props={this.props}
                    admin={profile.role == "admin" ? true : false}
                  />
                </div>
                {/* <div className="col s12 m5 offset-m1">
            <Notifications notifications={notifications} />
          </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    boards: state.firestore.ordered.boards,
    Books: state.firestore.ordered.Books,
    auth: state.firebase.auth,
    notifications: state.firestore.ordered.notifications,
    profile: state.firebase.profile,
  };
};

export default compose(
  firestoreConnect(() => [{ collection: "Books", orderBy: ["order"] }]),
  connect(mapStateToProps)
)(Books);
