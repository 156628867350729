import React, { Component } from "react";
import MartyrsList from "../Martyrs/MartyrsList";
import { connect } from "react-redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

class Dashboard extends Component {
  componentDidMount() {
    //console.log("mounded")
  }

  render() {
    const {
      boards,

      profile,

    } = this.props;
    //console.log("boards", boards)

    // if (!auth.uid) return <Redirect to='/signin' />

    return (
      <div className="">
        <section className="header-content dash-container">
          <div className="container-fluid">
            <div className="dashboard container">
              <div className="dash-container">
                {profile.role == "admin" ? (
                  <Link
                    className=""
                    to={{
                      pathname: "/CreateMartyrs",
                    }}
                  >
                    <Button variant="info">إضافة شهيد</Button>
                  </Link>
                ) : (
                  ""
                )}
              </div>

              <div className="">
                {/* col s12 m6 */}
                <div className="">
                  <MartyrsList martyrs={boards} props={this.props} />
                </div>
                {/* <div className="col s12 m5 offset-m1">
            <Notifications notifications={notifications} />
          </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //boards: state.firestore.ordered.boards,
    boards: state.firebase.ordered.boards,
    auth: state.firebase.auth,
    notifications: state.firestore.ordered.notifications,
    profile: state.firebase.profile,
  };
};

export default compose(
  firestoreConnect(() => [
    // { collection: 'boards',orderBy: ['name']},
    { collection: "notifications", limit: 3, orderBy: ["time", "desc"] },
    { collection: "Provinces", orderBy: [] },
    { collection: "jobs", orderBy: [] },
  ]),
  firebaseConnect(() => [{ path: "/boards", queryParams: ["name"] }]),
  connect(mapStateToProps)
)(Dashboard);
