import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Dashboard from "./components/Dashboard/Dashboard";
import MartyrsDetails from "./components/Martyrs/MartyrsDetails";
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import Home from "./components/Home/Home";
import CreateMartyrs from "./components/Martyrs/CreateMartyrs";
import EditMartyrs from "./components/Martyrs/EditMartyrs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/layout/Footer";
import Books from "./components/Books/Books";
import BookDetails from "./components/Books/BookDetails";
import CreateBooks from "./components/Books/CreateBooks";
import EditBook from "./components/Books/EditBook";

import Reports from "./components/Reports/Reports";
import ReportDetails from "./components/Reports/ReportDetails";
import CreateReports from "./components/Reports/CreateReports";
import EditReport from "./components/Reports/EditReport";

import Videos from "./components/Videos/Videos";
import VideoDetails from "./components/Videos/VideoDetails";
import CreateVideos from "./components/Videos/CreateVideos";
import EditVideo from "./components/Videos/EditVideo";


import $ from 'jquery';

class App extends Component {
  componentDidMount() {
    const menu = $('.open-menu');
    const objMenu = $('.floating-menu');
    const close = $('.close-menu');
    const objMenuLink = $('.floating-menu a');
     // Mobile menu open/close
    menu.on('click', function () {
      objMenu.addClass('expanded');
    });
    
    close.on('click', function () {
      $('nav').find('.expanded').removeClass('expanded');
      $('nav').find('.navbar-dropdown').slideUp();
    });
    
        // Floating menu hyperlink
        if ($('nav').hasClass('navbar-single-page')) {
          objMenuLink.on('click', function () {
              objMenu.removeClass('expanded');
          });
      }
  }
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <div className="page-loader"></div>
          <div className="wrapper">
            <Navbar />
            {/* render this if it's exist */}
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/Dashboard" component={Dashboard} />
      
              <Route path="/MartyrsDetails/:id" component={MartyrsDetails} />
           
              <Route path="/signin" component={SignIn} />
              <Route path="/signup" component={SignUp} />
              <Route path="/CreateMartyrs" component={CreateMartyrs} />
              <Route path="/EditMartyrs" component={EditMartyrs} />
              <Route exact path="/Books" component={Books} />
              <Route path="/BookDetails/:id" component={BookDetails} />
              <Route path="/CreateBooks" component={CreateBooks} />
              <Route path="/EditBook" component={EditBook} />

              <Route exact path="/Reports" component={Reports} />
              <Route path="/ReportDetails/:id" component={ReportDetails} />
              <Route path="/CreateReports" component={CreateReports} />
              <Route path="/EditReport" component={EditReport} />

              <Route exact path="/Videos" component={Videos} />
              <Route path="/VideoDetails/:id" component={VideoDetails} />
              <Route path="/CreateVideos" component={CreateVideos} />
              <Route path="/EditVideo" component={EditVideo} />

              <ToastContainer />
            </Switch>
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
